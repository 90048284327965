import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  styled,
} from "@mui/material";
import Tabform from "../../common/Tabform";
import MultiLanguageTab from "../LandingPage/MultiLanguageTab";

export default function CustomizedDialogs({ open, setOpen, data }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    if (newValue === "3") {
      window.location.href = "/demo";
      return;
    }
    setValue(newValue);
  };
  const CustomTab = styled(Tab)(({ theme }) => ({
    "&.Mui-selected": {
      color: "white", // Set the desired color for the selected tab label
    },
  }));

  return (
    <Box sx={{ width: "100%", }} className="box">
      <TabContext value={value}>
        <Box
          sx={{
            margin: "auto",
            background: "#1a1a1a",
            // paddingTop: {
            //   xs: 1,
            //   sm: 1,
            //   md: 2,
            //   lg: 3,
            //   xl: 3,
            // },
            // px: {
            //   xs: 1,
            //   sm: 1,
            //   md: 4,
            //   lg: 5,
            //   xl: 5,
            // },
            paddingBottom: {
              xs: 1,
              sm: 1,
              md: 3,
              lg: 5,
              xl: 5,
            },
            // maxHeight: `calc(100vh - 150px)`
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              background: "#313131",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TabList
              color="white"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile

              onChange={handleChange}
              aria-label="QuickSmart Features Tab"
              TabIndicatorProps={{
                style: { backgroundColor: "white" },
              }}
              sx={{
                "& .MuiTab-root": {
                  color: "gray", // Default color
                  padding: "10px 20px",
                  "&.Mui-selected": {
                    color: "white", // Text color when selected
                    backgroundColor: "#DE283B", // Background color when selected,
                    borderRadius: 2
                  },
                },
                ".MuiTabs-scrollButtons": {
                  color: "white", // Text color when selected
                }
              }}

            >
              <CustomTab
                label="Ultra-Responsive Voice Agent"
                value="1"
                sx={{
                  color: "white",
                  textTransform: "inherit",
                  letterSpacing: "1px",
                }}
                wrapped
              />
              <CustomTab
                label="Seamless Multilingual Voice Agent"
                value="2"
                sx={{
                  color: "white",
                  textTransform: "inherit",
                  letterSpacing: "1px",
                }}
                wrapped
              />
              <CustomTab
                label="Experience Web Call"
                value="3"
                sx={{
                  color: "white",
                  textTransform: "inherit",
                  letterSpacing: "1px",
                }}
                wrapped
              />
              {/* <CustomTab label="Chat" value="3" sx={{ color: "white" }} /> */}
            </TabList>
          </Box>


          <TabPanel value="1" sx={{ pb: 0 }}>
            <Tabform value="Ultra-Responsive Voice Agent" />
          </TabPanel>
          <TabPanel value="2" sx={{ pb: 0 }}>
            <MultiLanguageTab value="Seamless Multilingual Voice Agent" />
          </TabPanel>
          <TabPanel value="3" sx={{ pb: 0 }}>
            {/* <Tabform value="Trywebcall" /> */}
            Experience Web Call
          </TabPanel>
          {/* <TabPanel value="3">Item Three</TabPanel> */}
        </Box>
      </TabContext>
    </Box>
  );
}
