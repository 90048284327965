import React, { useEffect, useRef } from "react";
import "./index.css"; // Assuming you place your CSS in Poster.css

const Success = () => {
    const canvasRef = useRef(null);
    const intervalRef = useRef(null);

    useEffect(() => {
        const drawingCanvas = canvasRef.current;
        const ctx = drawingCanvas.getContext("2d");
        const viewWidth = drawingCanvas.width = 500; // Match Dialog width
        const viewHeight = drawingCanvas.height = 240; // Match Dialog height
        const timeStep = 1 / 60;
        let phase = 0;
        let particles = [];
        let loader;
        let exploader;

        const Point = function (x, y) {
            this.x = x || 0;
            this.y = y || 0;
        };

        const Particle = function (p0, p1, p2, p3) {
            this.p0 = p0;
            this.p1 = p1;
            this.p2 = p2;
            this.p3 = p3;
            this.time = 0;
            this.duration = 3 + Math.random() * 2;
            this.color = "#" + Math.floor(Math.random() * 0xffffff).toString(16);
            this.w = 8;
            this.h = 6;
            this.complete = false;
        };

        Particle.prototype.update = function () {
            this.time = Math.min(this.duration, this.time + timeStep);
            const f = Ease.outCubic(this.time, 0, 1, this.duration);
            const p = cubeBezier(this.p0, this.p1, this.p2, this.p3, f);
            const dx = p.x - this.x;
            const dy = p.y - this.y;
            this.r = Math.atan2(dy, dx) + Math.PI * 0.5;
            this.sy = Math.sin(Math.PI * f * 10);
            this.x = p.x;
            this.y = p.y;
            this.complete = this.time === this.duration;
        };

        Particle.prototype.draw = function () {
            ctx.save();
            ctx.translate(this.x, this.y);
            ctx.rotate(this.r);
            ctx.scale(1, this.sy);
            ctx.fillStyle = this.color;
            ctx.fillRect(-this.w * 0.5, -this.h * 0.5, this.w, this.h);
            ctx.restore();
        };

        const Loader = function (x, y) {
            this.x = x;
            this.y = y;
            this.r = 24;
            this._progress = 0;
            this.complete = false;
        };

        Loader.prototype.reset = function () {
            this._progress = 0;
            this.complete = false;
        };

        Object.defineProperty(Loader.prototype, "progress", {
            set: function (p) {
                this._progress = p < 0 ? 0 : p > 1 ? 1 : p;
                this.complete = this._progress === 1;
            },
            get: function () {
                return this._progress;
            },
        });

        Loader.prototype.draw = function () {
            ctx.fillStyle = "transparent";
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.r, -Math.PI * 0.5, Math.PI * 2 * this._progress - Math.PI * 0.5);
            ctx.lineTo(this.x, this.y);
            ctx.closePath();
            ctx.fill();
        };

        const Exploader = function (x, y) {
            this.x = x;
            this.y = y;
            this.startRadius = 24;
            this.time = 0;
            this.duration = 0.4;
            this.progress = 0;
            this.complete = false;
        };

        Exploader.prototype.reset = function () {
            this.time = 0;
            this.progress = 0;
            this.complete = false;
        };

        Exploader.prototype.update = function () {
            this.time = Math.min(this.duration, this.time + timeStep);
            this.progress = Ease.inBack(this.time, 0, 1, this.duration);
            this.complete = this.time === this.duration;
        };

        Exploader.prototype.draw = function () {
            ctx.fillStyle = "transparent";
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.startRadius * (1 - this.progress), 0, Math.PI * 2);
            ctx.fill();
        };

        function initDrawingCanvas() {
            drawingCanvas.width = viewWidth;
            drawingCanvas.height = viewHeight;
            createLoader();
            createExploader();
            createParticles();
        }

        function createLoader() {
            loader = new Loader(viewWidth * 0.5, viewHeight * 0.5);
        }

        function createExploader() {
            exploader = new Exploader(viewWidth * 0.5, viewHeight * 0.5);
        }

        function createParticles() {
            for (let i = 0; i < 128; i++) {
                const p0 = new Point(viewWidth * 0.5, viewHeight * 0.5);
                const p1 = new Point(Math.random() * viewWidth, Math.random() * viewHeight);
                const p2 = new Point(Math.random() * viewWidth, Math.random() * viewHeight);
                const p3 = new Point(Math.random() * viewWidth, viewHeight + 64);
                particles.push(new Particle(p0, p1, p2, p3));
            }
        }

        function update() {
            switch (phase) {
                case 0:
                    loader.progress += 1 / 45;
                    break;
                case 1:
                    exploader.update();
                    break;
                case 2:
                    particles.forEach((p) => p.update());
                    break;
                default:
                    break;
            }
        }

        function draw() {
            ctx.clearRect(0, 0, viewWidth, viewHeight);
            switch (phase) {
                case 0:
                    loader.draw();
                    break;
                case 1:
                    exploader.draw();
                    break;
                case 2:
                    particles.forEach((p) => p.draw());
                    break;
                default:
                    break;
            }
        }

        function loop() {
            update();
            draw();
            if (phase === 0 && loader.complete) phase = 1;
            else if (phase === 1 && exploader.complete) phase = 2;
            else if (phase === 2 && checkParticlesComplete()) {
                phase = 0;
                loader.reset();
                exploader.reset();
                particles.length = 0;
                createParticles();
            }
            requestAnimationFrame(loop);
        }

        function checkParticlesComplete() {
            return particles.every((p) => p.complete);
        }

        const Ease = {
            outCubic: (t, b, c, d) => c * (--t * t * t + 1) + b,
            inBack: (t, b, c, d, s = 1.70158) => c * (t /= d) * t * ((s + 1) * t - s) + b,
        };

        function cubeBezier(p0, c0, c1, p1, t) {
            const nt = 1 - t;
            return new Point(
                nt * nt * nt * p0.x + 3 * nt * nt * t * c0.x + 3 * nt * t * t * c1.x + t * t * t * p1.x,
                nt * nt * nt * p0.y + 3 * nt * nt * t * c0.y + 3 * nt * t * t * c1.y + t * t * t * p1.y
            );
        }

        initDrawingCanvas();
        requestAnimationFrame(loop);

        // Set up the interval to regenerate canvas every 2 seconds
        intervalRef.current = setInterval(() => {
            particles.length = 0;
            createParticles();
        }, 1000);

        return () => {
            // Clean up the interval on component unmount
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);
    return (
        <div className="poster">
            <div className="austin">congratulations</div>
            <canvas id="drawing_canvas" ref={canvasRef} />
        </div>
    );
};

export default Success;
