import client from "../client";

export const login = (params) => {
  return client.post(`/v1/auth/login`, params);
};

export const register = (params) => {
  const user = {
    ...params,
    "auth-type": "user",
  };
  return client.post(`/api/users/register`, user);
};

export const requestCall = (params) => {
  return client.post(`/v1/customer`, params);
};

export const getCases = () => {
  return client.get(`/v1/usecase`);
};

export const getAgent = () => {
  return client.get(`/global/agent`);
};

export const getAgentCall = (params) => {
  return client.post(`/global/call/agent`,params);
};

export const getCountry = () => {
  return client.get(`/v1/country`);
};

export const getLanguage = () => {
  return client.get(`/global/language-voices`);
};

export const tryoutCall = (params) => {
  return client.post(`/global/call/try-out`,params)
}