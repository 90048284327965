import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { requestCall, getAgent, getCountry, getAgentCall } from "../../api/auth";
import { makeStyles } from "@mui/styles";
import Modal from "../../common/Tabform/modal";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase"; // Adjust the import path as necessary
import { toast } from 'react-toastify';
import { MuiPhone } from "./MuiPhone";
import ReCAPTCHA from "react-google-recaptcha";
const disposableEmailProviders = [
  "yopmail.com",
  "mailinator.com",
  "guerrillamail.com",
  "10minutemail.com",
  "throwawaymail.com",
  "temp-mail.org",
  "trashmail.com",
  "getnada.com",
  "maildrop.cc",
  "fakeinbox.com",
  "emailondeck.com",
  "mailboxy.com",
];
const validationSchema = Yup.object({
  name: Yup.string().required("Customer Name is required"),
  // captcha: Yup.string()
  //   .nullable()
  //   .required("Please verify you are not a robot"),
  phone: Yup.string()
    .required("Phone is required")
    .matches(/^\+\d{1,4}\d{6,}$/gm, "Invalid phone number format"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
    .test(
      "is-not-disposable",
      "Disposable email providers are not allowed",
      function (value) {
        const domain = value?.split("@")?.[1];
        return !disposableEmailProviders.includes(domain);
      }
    ),
});

const useStyles = makeStyles((theme) => ({
  selectIcon: {
    fill: "white !important",
  },
}));
function Index(bound) {
  const [cases, SetCases] = React.useState([]);
  const [countryy, SetCountry] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [contact, setContact] = React.useState("");
  const [toast, setToast] = React.useState(false);
  const captchaRef = React.useRef(null);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast(false);
  };

  async function fetCasesData() {
    try {
      const response = await getAgent();

      const data = response.data;
      SetCases(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async function fetCountryData() {
    try {
      const response = await getCountry();

      const data = response.data;
      SetCountry(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  React.useEffect(() => {
    fetCasesData();
    fetCountryData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      cases: "",
      country: "",
      captcha: "",
      // source: "from-amit",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      try {
        const token = captchaRef.current
          ? captchaRef.current.getValue() || ""
          : "";

        const obj = {
          // email: values.email,
          // name: values.name,
          agentId: values.cases,
          countryId: values.country,
          contact: values.phone,
          // type: bound.value,
          token: "parth@google",
          // source: values.source,
        };
        // captchaRef.current.reset();

        const response = await getAgentCall(obj);
        setToast(true);
        console.log("API call successful:", response);

        setContact(values.phone);
        captchaRef.current.reset();
        formik.resetForm();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  });
  const classes = useStyles();
  function onChange(value) {
    console.log("Captcha value:", value);
    formik.setFieldValue("captcha", value);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography
          textAlign={"center"}
          variant="h4"
          color="white"
          sx={{
            fontSize: {
              xs: "1.0rem", // for small devices
              sm: "1.3rem", // for medium devices
              md: "1.30rem", // for large devices
              lg: "2rem", // for extra large devices
              xl: "2rem", // adjust the values as per your design requirements
            },
          }}
          fontWeight={700}
          letterSpacing={1}
        >
          Real-Time Agent Call
          {/* {bound.value === "inbound"
            ? "Try inbound call flow"
            : "Check our outbound flow"} */}
        </Typography>

        <InputLabel sx={{ color: "white", mt: 1, mb: 1, textAlign: "left" }}>
          Name
        </InputLabel>

        <TextField
          id="outlined-basic-small"
          fullWidth
          placeholder="Enter your name"
          name="name"
          size="small"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          sx={{
            input: {
              color: "white", // Text color
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color
              },
              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
            "& .MuiFormHelperText-root": {
              color: "white",
            },
          }}
        />

        <InputLabel sx={{ textAlign: "left", mt: 1, color: "white", mb: 1 }}>
          Phone
        </InputLabel>

        <MuiPhone
          name="phone"
          value={formik.values.phone}
          onChange={(value) => formik.setFieldValue("phone", value)}
          onBlur={formik.handleBlur} // Optional: if you want to handle formik touch
        />
        {/* muiiii */}
        {formik.touched.phone && (
          <FormHelperText error id="helper-text-country">
            {formik.errors.phone}
          </FormHelperText>
        )}

        <InputLabel sx={{ textAlign: "left", color: "white", mt: 1, mb: 1 }}>
          Email
        </InputLabel>

        <TextField
          id="outlined-basic-small"
          fullWidth
          placeholder="Enter your email"
          name="email"
          size="small"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{
            input: {
              color: "white", // Text color
            },

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white", // Border color
              },

              "&:hover fieldset": {
                borderColor: "white", // Border color on hover
              },

              "&.Mui-focused fieldset": {
                borderColor: "white", // Border color when focused
              },
            },

            "& .MuiFormHelperText-root": {
              color: "white", // Helper text color (optional)
            },
          }}
        />

        <InputLabel sx={{ textAlign: "left", color: "white", mt: 1, mb: 1 }}>
          Agent
        </InputLabel>
        <FormControl size="small" fullWidth>
          <InputLabel
            id="demo-small-label"
            sx={{ color: "#6A6A6A", fontWeight: 600 }}
          >
            Select Agent
          </InputLabel>
          <Select
            labelId="demo-small-label"
            id="demo-small-label"
            value={formik.values.cases}
            name="cases"
            label="Select Agent"
            onChange={formik.handleChange}
            classes={{ icon: classes.selectIcon }}
            sx={{
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white !important",
                  color: "white !important",
                },
              },
            }}
            style={{
              color: "white",
            }}
          >
            {cases &&
              cases.map((_el) => (
                <MenuItem key={_el.id} value={_el?.id}>
                  {_el?.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <InputLabel sx={{ textAlign: "left", color: "white", mt: 1, mb: 1 }}>
          Country
        </InputLabel>
        <FormControl size="small" fullWidth>
          <InputLabel
            id="demo-select-small-label"
            sx={{ color: "#6A6A6A", fontWeight: 600 }}
          >
            Select Country
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small-label"
            value={formik.values.country}
            name="country"
            label=" Select Country"
            onChange={formik?.handleChange}
            classes={{ icon: classes.selectIcon }}
            sx={{
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white !important",
                  color: "white",
                },
              },
            }}
            style={{
              color: "white",
              "& .MuiSelect-icon": {
                color: "white", // Set the desired color for the icon
              },
            }}
          >
            {countryy &&
              countryy.map((_el) => {
                return (
                  <MenuItem key={_el.id} value={_el.id}>
                    {_el.country}
                  </MenuItem>
                );
              })}
            ``
          </Select>
        </FormControl>

        {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_SITE_KEY}
            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={onChange}
          />
        </Box>

        <Typography
          color={"red"}
          style={{ display: "flex", justifyContent: "center" }}
          variant="body1"
        >
          {formik.errors.captcha && <div>{formik.errors.captcha}</div>}
        </Typography> */}

        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ background: "#DE283B" }}
          >
            {/* {bound.value === "inbound" ? "Try Now" : "Try Out"} */}
            Submit
          </Button>
        </Box>
      </form>
      <Modal open={open} setOpen={setOpen} contact={contact} />
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Calling Successfully.
        </Alert>
      </Snackbar>
    </>
  );
}

export default Index;
