import React from "react";
import {
  // Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  // DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import { MuiPhone } from "../../common/Tabform/MuiPhone";
import { getCases, getCountry, getLanguage, tryoutCall } from "../../api/auth";
// import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../assets/images/logo/quicksmart_demo.png";
import * as Yup from "yup";
import styles from "./index.module.scss";
import Background from "../../common/Background";
import Success from "../../common/Success";

const validationSchema = Yup.object({
  usecase: Yup.string().required("Select UseCase"),
  language: Yup.string().required("Language is required"),
  phone: Yup.string()
    .required("Phone is required")
    .matches(/^\+\d{1,4}\d{6,}$/gm, "Invalid phone number format"),
});

const TryOut = () => {
  const [cases, setCases] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [languageData, setLanguageData] = React.useState([]);
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  // const captchaRef = React.useRef(null);

  async function fetCasesData() {
    try {
      const response = await getCases();
      const data = response.data;
      setCases(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async function fetCountryData() {
    try {
      const response = await getCountry();
      const data = response.data;
      setCountry(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async function fetLanguageData() {
    try {
      const response = await getLanguage();
      const data = response.data;
      setLanguageData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  React.useEffect(() => {
    fetCasesData();
    fetCountryData();
    fetLanguageData();
  }, []);

  const formik = useFormik({
    initialValues: {
      usecase: "",
      language: "",
      phone: "",
      country: "",
      captcha: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("Form values", values);
      try {
        // const token = captchaRef.current
        //   ? captchaRef.current.getValue() || ""
        //   : "";
        const token = "parth@google"
        const obj = {
          contact: values?.phone,
          countryId: values?.country,
          usecaseId: values?.usecase,
          token: token,
          voiceId: values?.language,
        };
        // tryoutCall(obj);
        const response = await tryoutCall(obj); // Await the API call
        console.log("API call successful:", response);
        setOpenSuccessDialog(true);
        // formik.resetForm();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  });
  // function onChange(value) {
  //   console.log("Captcha value:", value);
  //   formik.setFieldValue("captcha", value);
  // }

  return (
    <Grid container className={styles.main_container}>
      <Background />
      <Grid
        item
        xs={12}
        md={6}
        lg={5}
        xl={4}
        className={styles.form_box_container}
      >
        <Grid sx={{ display: "flex", justifyContent: "center", mt: "0.5rem", mb: "1rem" }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              height: "100%", marginRight: "10px", width: "50%"
            }}
          />
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <InputLabel sx={{ textAlign: "left", color: "white", mt: 1, mb: 1, fontWeight: "600" }}>
            Agent Role
          </InputLabel>
          <FormControl size="small" fullWidth>
            <Select
              value={formik.values.usecase}
              name="usecase"
              onChange={formik.handleChange}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white !important",
                    color: "white !important",
                  },
                  "& .MuiSelect-icon": {
                    color: "white",
                  },
                  "&.MuiMenu-paper": {
                    background: "black"
                  }
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "black", // Set the background color to black
                    color: "white"
                  },
                },
              }}
              style={{
                color: "white",
              }}
            >
              <MenuItem disabled value="">
                <em>Select agent role</em>
              </MenuItem>
              {cases?.map((_el) => (
                <MenuItem key={_el.id} value={_el.id}>
                  {_el.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.usecase && (
            <FormHelperText error id="helper-text-usecase">
              {formik.errors.usecase}
            </FormHelperText>
          )}

          <InputLabel sx={{ fontWeight: "600", textAlign: "left", color: "white", mt: "14px", mb: 1 }}>
            Voice
          </InputLabel>
          <FormControl size="small" fullWidth>
            <Select
              value={formik.values.language}
              name="language"
              onChange={formik.handleChange}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white !important",
                    color: "white !important",
                  },
                  "& .MuiSelect-icon": {
                    color: "white",
                  },
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "black", // Set the background color to black
                    color: "white"
                  },
                },
              }}
              style={{
                color: "white",
              }}
            >
              <MenuItem disabled value="">
                <em>Select Voice</em>
              </MenuItem>
              {languageData?.map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name} - {item?.language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formik.touched.language && (
            <FormHelperText error id="helper-text-language">
              {formik.errors.language}
            </FormHelperText>
          )}

          <InputLabel sx={{ fontWeight: "600", textAlign: "left", mt: "14px", color: "white", mb: 1 }}>
            Contact
          </InputLabel>

          <MuiPhone
            name="phone"
            value={formik.values.phone}
            onChange={(value) => formik.setFieldValue("phone", value)}
            onBlur={formik.handleBlur} // Optional: if you want to handle formik touch
          />
          {/* muiiii */}
          {formik.touched.phone && (
            <FormHelperText error id="helper-text-phone">
              {formik.errors.phone}
            </FormHelperText>
          )}

          <InputLabel sx={{ fontWeight: "600", textAlign: "left", color: "white", mt: "14px", mb: 1 }}>
            Country
          </InputLabel>
          <FormControl size="small" fullWidth>
            <Select
              value={formik.values.country}
              name="country"
              onChange={formik?.handleChange}
              sx={{
                "&.MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white !important",
                    color: "white",
                  },
                  "& .MuiSelect-icon": {
                    color: "white",
                  },
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "black", // Set the background color to black
                    color: "white"
                  },
                },
              }}
              style={{
                color: "white",
              }}
            >
              <MenuItem disabled value="">
                <em>Select your country</em>
              </MenuItem>
              {country &&
                country?.map((_el) => {
                  return (
                    <MenuItem key={_el.id} value={_el.id}>
                      {_el.country}
                    </MenuItem>
                  );
                })}
              ``
            </Select>
          </FormControl>

          {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={process.env.REACT_APP_SITE_KEY}
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={onChange}
            />
          </Box> */}

          <Grid className={styles.btn_main_container} >
            <Button
              variant="contained"
              type="submit"
              className={styles.btn_container}
            >
              Call
            </Button>
          </Grid>
        </form>
      </Grid>
      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        PaperProps={{
          style: {
            background: "#1a1a1a",
            borderRadius: "15px",
            boxShadow: "0px 4px 20px rgba(255, 255, 255, 0.1)", // Light shadow added
          },
        }}
        BackdropProps={{
          sx: {
            backdropFilter: "blur(5px)", // Apply blur effect
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent backdrop
          },
        }}
        aria-labelledby="congrats-dialog-title"
        aria-describedby="congrats-dialog-description"
      >
        <DialogContent sx={{ height: "240px", width: "500px", position: "relative" }}>
          <Success />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            sx={{
              color: "white"
            }}
            variant="text"
            onClick={() => setOpenSuccessDialog(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TryOut;
