import './index.css'
import React from 'react'
export default function Index() {
    const starCount = 25;

    // Function to generate a random number between a min and max value
    const randomRange = (min, max) => Math.random() * (max - min) + min;
    return (
        <div className='bg'>
            <div className="stars">
                {Array.from({ length: starCount }).map((_, i) => {
                    // Random properties for each star
                    const starStyle = {
                        '--star-tail-length': `${randomRange(5, 7.5)}em`,
                        '--top-offset': `${randomRange(0, 100)}vh`,
                        '--fall-duration': `${randomRange(6, 12)}s`,
                        '--fall-delay': `${randomRange(0, 5)}s`, // Reducing the delay
                        '--initial-x': `${randomRange(-10, 100)}vw`, // Start position in viewport width
                    };

                    return <div key={i} className="star" style={starStyle}></div>;
                })}
            </div>
        </div>
    );
}
