import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/third-party/react-international-phone.scss";
import "./assets/css/third-party/quill.scss";
import "./App.css";
import "aos/dist/aos.css";
import AOS from "aos";
import React from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ToastContextProvider } from "./context/Toast";
AOS.init();

// import Page404 from "./view/Page404";
// import ProtectedRoute from "./components/ProtectedRoute";
let theme = createTheme();
theme = responsiveFontSizes(theme); // Enhance the theme for responsive fonts

// import Helmet from 'react-helmet';
const Routes = () => {
  return useRoutes(routes);
};
function App() {
  return (
    <ToastContextProvider>
      <HelmetProvider>
        <Helmet titleTemplate="%s | " defaultTitle="Quick & Smart AI Voice Technology | Effortless AI Calls"></Helmet>
      </HelmetProvider>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </ToastContextProvider>
  );
}

export default App;
