// import React from "react";

// const HeroHeader = () => {
//   return <>hii</>;
// };

// export default HeroHeader;

import React from "react";
import {
  Slide,
  useScrollTrigger,
  AppBar,
  Toolbar,
  Button,
  Box,
  Grid,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/images/logo/quicksmart__1__720.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const HeroHeader = () => {
  const trigger = useScrollTrigger();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const menuItems = [
    // { to: "/", text: "Home" },
    { to: "https://admin.quicksmart.ai", text: "Dashboard" },
    // { to: "/pricing", text: "Pricing" },
  ];

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          style={{
            background: "#1a1a1a",
          }}
        >
          <Toolbar>
            <Container maxWidth={"xl"}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {/* Your Logo */}
                <Grid item>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "40px", marginRight: "10px" }}
                  />
                </Grid>

                {/* Navigation Buttons */}
                <Grid item>
                  {isSmallScreen ? (
                    <>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDrawerOpen}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Drawer
                        anchor="left"
                        open={drawerOpen}
                        onClose={handleDrawerClose}
                        sx={{
                          "& .css-4t3x6l-MuiPaper-root-MuiDrawer-paper": {
                            backgroundColor: "#1a1a1a",
                            width: "50%", // Change the background color as needed
                          },
                        }}
                      >
                        <List>
                          {menuItems.map((item, index) => (
                            <ListItem
                              key={index}
                              component={Link}
                              to={item.to}
                              onClick={handleDrawerClose}
                              sx={{
                                color: "white",
                                background:
                                  location.pathname === item.to
                                    ? "#313131" // Set the active background color
                                    : "#1a1a1a", // Set the default background color
                                display: "block",
                                textDecoration: "none", // Optional: Remove underline
                              }}
                            >
                              <ListItemText primary={item.text} />
                            </ListItem>
                          ))}
                        </List>
                      </Drawer>
                    </>
                  ) : (
                    <Box>
                      {/* <Link color="inherit" to={"/"}>
                        <Button
                          color="inherit"
                          sx={{
                            color: location.pathname === "/" ? "white" : "grey", // Set the active text color
                          }}
                        >
                          Home
                        </Button>
                      </Link> */}
                      <a href="https://admin.quicksmart.ai" style={{ textDecoration: 'none' }}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          sx={{
                            borderRadius: "24px",
                            mx: 1,
                            color: "#ffffffad",
                            fontWeight: 700
                          }}
                        >
                          Dashboard
                        </Button>
                      </a>
                      <Button variant="contained"
                        sx={{
                          borderRadius: "24px",
                          "&:hover": {
                            color: "white" // Change text color to white on hover
                          }
                        }}
                        href="#trail"

                      // onClick={() => navigate('/tryout')}
                      >Let's try</Button>
                      {/* <Link color="inherit" to={"/pricing"}>
                        <Button
                          color="inherit"
                          sx={{
                            color:
                              location.pathname === "/pricing" ? "white" : "grey", // Set the active text color
                          }}
                        >
                          Pricing
                        </Button>
                      </Link> */}
                      {/* <Link color="inherit" to={"/demo"}>
                        <Button
                          variant="contained"
                          color="inherit"
                          sx={{
                            color:
                              location.pathname === "/demo" ? "white" : "grey", // Set the active text color
                          }}
                        >
                          Try The Demo
                        </Button>
                      </Link> */}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </Slide>
    </>
  );
};

export default HeroHeader;
